import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { StaticQuery, graphql, Link as GatsbyLink  } from 'gatsby'
import { connect } from 'react-redux';
// import logo from "../../images/logo-moabiterinsel.svg"
import { setIsMapView } from '../../state/actions'

const Content = ( props ) => {
  const { 
    data,
    setIsMapView,
    content,
    id
  } = props;
  return (

    <div
      key={id}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: content }}
    />


  )}




const StandardContent = (props) => {
  const {
    content,
    id
  } = props;
  // console.log('>>>> title: ', title, ' >>>> subitle: ', subtitle)
  return <Content id={id} content={content} {...props} />
  // return <FooterMenu setIsMapView={setIsMapView} data={data.wpMenu? data.wpMenu.menuItems.nodes : []} {...props} />
  //     }}
  //   />
}


export default StandardContent
