import React, { useEffect }  from 'react'
import styled from '@emotion/styled'
import { globalHistory } from "@reach/router"
import { graphql } from 'gatsby'
import { connect } from 'react-redux';
import FluidImage from "../components/FluidImage"
import HeroHeadline from '../components/header/HeroHeadline';
import StandardContent from '../components/StandardContent'
// import Layout from '../containers/Layout'

import { setInitalizedToTrue, setIsMapView, setIsCategoryView, updateRoute } from '../state/actions'


const StyledContentImage = styled.div`   
  background-color:  ${({ theme }) => theme.mi_background_real};
  margin-top: 72px;
  margin-bottom: 60px;
  // max-width:1140px;

  img{
    max-width: 100%;
  }
  @media(min-width: 768px){
    
    display: flex;
    justify-content: center;
    img{
      max-width: 945px;
    }
  }
  `

const StyledStandardContentWrapper = styled.div`   
  background-color:  ${({ theme }) => theme.mi_background_real};
  padding: 48px 24px 72px 24px;
  max-width:1140px;
  overflow: scroll;
  @media(min-width: 1140px){
    margin: 0 auto;
  }
  `

const StyleFluidImageWrapper = styled.div`   
    flex: 1 0 100%;
    width: 100%;
    margin-top: 72px;
    margin-bottom: 42px;

    @media(min-width: 768px){
      width: 768px;
    }
    @media(min-width: 1140px){
      width: 945px;
      margin-bottom: 76px;
      margin-top: 96px;
    }
  `

  const StyleFluidImageWrapperHero = styled.div`   
  width: 100%;
  margin-top: 0
  margin-bottom: 48px;


  @media(min-width: 1140px){
    margin-bottom: 121px;
    margin-top: 0
  }
`
const PageTemplate = (props) => {
  // console.log('\n\n\› PageTemplate props: ');
  // console.log(JSON.stringify(props, null, 4));

  const {
    data: {
      wpPage: { content, cleanTitle, mi_standard },
    },
    pageContext,
    isStateInitialized, 
    isMapView,
    setInitalizedToTrue,
    setIsMapView,
    setIsCategoryView,
    updateRoute
  } = props

  const nodes = mi_standard.miStandardContent? mi_standard.miStandardContent.map((node, index) => {
    return {...node, key : `${node.fieldGroupName}${index}`}
  }) : [];


  useEffect(() => {
    
    console.log('>>> mounted')
    if (!isStateInitialized) {
      setInitalizedToTrue();
      setIsMapView(false);
      setIsCategoryView(false);
      // updateRoute(globalHistory.location)
    }
  }, []);


  return (
    <>
      <HeroHeadline key={cleanTitle} title={cleanTitle} subtitle={mi_standard.miStandardSubheadline} />
      {mi_standard.miStandardHeroImage ? (
        <StyleFluidImageWrapperHero>
          <FluidImage key={`hero${mi_standard.miStandardHeroImage.sourceUrl}`} image={mi_standard.miStandardHeroImage} style={{marginBottom: '121px'}} /> 
        </StyleFluidImageWrapperHero>
      ) : null }

      <StyledStandardContentWrapper>
        {

          nodes.map(( node ) => {

            // return <pre>{JSON.stringify(node, null, 4)}</pre>
            switch(node.fieldGroupName){
            case 'page_MiStandard_MiStandardContent_MiStandardContentText':
              return <StandardContent key={node.key} content={node.miStandardContentTextWysiwyg} />
            case 'page_MiStandard_MiStandardContent_MiStandardContentImage':
              return (
                <StyleFluidImageWrapper key={node.miStandardContentImageUrl.sourceUrl}>
                  <FluidImage image={node.miStandardContentImageUrl} style={{marginBottom: '96px', marginTop:'96px'}} />
                </StyleFluidImageWrapper>
              )
            default:
              return null
            }
          })

        } 
      </StyledStandardContentWrapper>
    </>
  )
}



export const pageQuery = graphql`
    query PageQuery($id: String!) {
      wpPage(id: {eq: $id}) {
          ...PageContent
          mi_standard {
            miStandardSubheadline
            miStandardContent {
              ... on WpPage_MiStandard_MiStandardContent_MiStandardContentText {
                fieldGroupName
                miStandardContentTextWysiwyg
              }
              ... on WpPage_MiStandard_MiStandardContent_MiStandardContentImage {
                fieldGroupName
                miStandardContentImageCaption
                miStandardContentImageUrl {
                  sourceUrl
                  altText
                  caption
                  imageFile {
                    childImageSharp {
                        fluid(
                          maxHeight: 1400, 
                          quality: 80, 
                          cropFocus: CENTER,
                          traceSVG: { background: "#fff", color: "#f2ebe7" }
                        ) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                  }
                }
              }
            }
            miStandardHeroImage {
              sourceUrl
              altText
              caption
                  imageFile {
                    childImageSharp {
                      fluid(
                        maxHeight: 1000, 
                        quality: 80, 
                        cropFocus: CENTER,
                        traceSVG: { background: "#fff", color: "#f2ebe7" }
                      ) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
            }
          }

      }
    }
`

const mapStateToProps = state => {
  // console.log('mapStateToProps standardPage: ', state)
  return {
    isStateInitialized: state.isStateInitialized,
    isMapView: state.map.isMapView
  }
};

const mapDispatchToProps = {
  setInitalizedToTrue,
  setIsMapView,
  setIsCategoryView,
  updateRoute,
};


export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate)
